import bg from './bg.jpg';
import './App.scss';

function App() {
  return (
      <div className="forest-bathing-bg" style={{ backgroundImage: `url('${bg}')` }}>
    <div className="forest-bathing">
        <div className="page-wrapper">
          <div className="heading">
            <h1>Bis bald im Wald</h1>
            <h2>Sommer 2022</h2>
          </div>

          <div className="tree-wrapper">
            <div className="tree">
              <div className="tree-trunk"/>
              <div className="tree-branch tree-branch-1"/>
              <div className="tree-branch tree-branch-2"/>
              <div className="tree-branch tree-branch-3"/>
              <div className="tree-branch tree-branch-4"/>
            </div>
          </div>
        </div>

        <div className="contact">
          <p>Schon neugierig?</p>
          <a href="mailto:info@waldbaden-stallhofen.at">info@waldbaden-stallhofen.at</a>
        </div>

    </div>
      </div>
  );
}

export default App;
